import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { useTrialOfferPromotionModalContent } from 'hooks/useRedeemCouponForTrialOffer';

import { FIRST_CONSULTATION_ROUTE } from 'Apps/Consultation/constants';
import GiftWithPurchaseBanner from 'Apps/PromotionTerms/Scenes/GiftWithPurchase/GiftWithPurchaseBanner';

import { BottomModal } from 'Components/BottomModal';
import PromotionalModalPostPurchaseCrossSell from 'Components/PromotionalModalPostPurchaseCrossSell';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import {
  CheckoutBanner,
  ClickableCta,
  DefaultBanner,
  LinkCta,
  LinkCtaNoir,
  LinkCtaWhite,
  LoadingBanner,
  LongLinkCta,
  MembershipBanner,
  ReferFriendSideLink,
  UnstyledButton,
} from 'Blocks/Banner/BannerComponents';

import * as bannerContent from 'assets/content/banner';
import skincareMinisContent from 'assets/content/skincareMinisModal';
import trialOfferGWPMixedWithSkincareMinisModalContent from 'assets/content/trialOfferGWPMixedWithSkincareMinisModal';
import trialOfferMixedWithSkincareMinisModalContent from 'assets/content/trialOfferMixedWithSkincareMinisModal';

import { handleKeyPress } from 'utils/a11y';

import { trialOfferEvergreen } from 'dux/featureFlags/selectors';
import { getUserCurrency } from 'dux/user/selectors';
import { Variant } from 'dux/featureFlags/postPurchaseCrossSellOfer';

export const defaultBanner = {
  shouldRender: () => true, // This should always return true
  render: (hideBannerLink, { nextMode }) => (
    <DefaultBanner data-testid="banner" props={{ hasLink: !hideBannerLink }}>
      <LongLinkCta
        data-click="refer"
        data-from="banner"
        data-testid="default-banner"
        nextMode={nextMode}
        to="/reviews"
      >
        {bannerContent.defaultContent}
      </LongLinkCta>
      {!hideBannerLink && <ReferFriendSideLink nextMode={nextMode} />}
    </DefaultBanner>
  ),
};

const RenderTrialOfferBanner = () => {
  const [isOpen, setIsOpen] = useState();
  const { content, variant } = useTrialOfferPromotionModalContent();

  return (
    <>
      <BottomModal
        content={content?.bottomModal}
        dataFrom="membership-modal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        testPrefix={`bottom-modal-trial-offer-${variant}`}
      />
      <ClickableCta
        aria-label="Open trial offer modal"
        data-click="offer-popup"
        data-from="banner"
        data-testid={`banner-trial-offer-${variant}`}
        onClick={() => setIsOpen(true)}
        onKeyPress={handleKeyPress(() => setIsOpen(true))}
        role="button"
        tabIndex={0}
      >
        {content?.banner}
      </ClickableCta>
    </>
  );
};

const RenderTrialOfferMixedSkincareMinisBanner = () => {
  const [isOpen, setIsOpen] = useState();
  const currency = useAppSelector(getUserCurrency);
  const { variant } = useTrialOfferPromotionModalContent();
  const content =
    variant !== trialOfferEvergreen
      ? trialOfferGWPMixedWithSkincareMinisModalContent
      : trialOfferMixedWithSkincareMinisModalContent;

  return (
    <>
      <BottomModal
        backgroundColor={theme.colors.tertiary[200]}
        content={content.bottomModal[currency]}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <ClickableCta
        aria-label="Open skincare minis + starter set offer modal"
        data-click="offer-popup"
        data-from="banner"
        onClick={() => setIsOpen(true)}
        onKeyPress={handleKeyPress(() => setIsOpen(true))}
        role="button"
        tabIndex={0}
      >
        {content.banner}
      </ClickableCta>
    </>
  );
};

const RenderSkincareMinisBanner = () => {
  const [isOpen, setIsOpen] = useState();
  const userCurrency = useAppSelector(getUserCurrency);

  return (
    <>
      <BottomModal
        backgroundColor={theme.colors.tertiary[200]}
        content={skincareMinisContent.bottomModal[userCurrency]}
        dataFrom="membership-modal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        testPrefix="bottom-modal-skincare-minis"
      />
      <ClickableCta
        aria-label="Open skincare minis offer modal"
        data-click="offer-popup"
        data-from="banner"
        data-testid="banner-skincare-minis"
        onClick={() => setIsOpen(true)}
        onKeyPress={handleKeyPress(() => setIsOpen(true))}
        role="button"
        tabIndex={0}
      >
        {skincareMinisContent.banner}
      </ClickableCta>
    </>
  );
};

export const trialOfferBanner = {
  shouldRender: ({ showTrialOffer }) => showTrialOffer,
  render: () => <RenderTrialOfferBanner />,
};

export const skincareMinisBanner = {
  shouldRender: ({ showSkincareStarterSetPromo }) => showSkincareStarterSetPromo,
  render: () => <RenderSkincareMinisBanner />,
};

export const trialOfferMixedSkincareMinisBanner = {
  shouldRender: ({
    showTrialOffer,
    showSkincareStarterSetPromo,
    hasHaircareSubscriptionInAnyState,
    hasSkincareSubscriptionInAnyState,
  }) =>
    showTrialOffer &&
    showSkincareStarterSetPromo &&
    !hasHaircareSubscriptionInAnyState &&
    !hasSkincareSubscriptionInAnyState,
  render: () => <RenderTrialOfferMixedSkincareMinisBanner />,
};

export const checkoutMessage = {
  shouldRender: ({ locationPathname }) =>
    /\/checkout\/(haircare|skincare)\/(account-details|shipping-address|payment)/.test(
      locationPathname
    ),
  render: () => (
    <CheckoutBanner data-testid="satisfaction-guarantee-banner">
      Love your first order (or it&apos;s on us)
    </CheckoutBanner>
  ),
};

export const noFreeShippingContent = {
  shouldRender: ({ locationSearchParams }) => {
    return locationSearchParams?.has('origin') && locationSearchParams?.get('origin') === 'yjfwm';
  },
  render: (hideBannerLink, { nextMode }) => (
    <DefaultBanner data-testid="banner" props={{ hasLink: !hideBannerLink }}>
      <span data-testid="no-free-shipping-content-banner">
        {bannerContent.noFreeShippingContent}
      </span>
      {!hideBannerLink && <ReferFriendSideLink nextMode={nextMode} />}
    </DefaultBanner>
  ),
};

export const referralCta = {
  shouldRender: ({ hasHairAndSkinSubscriptions }) => hasHairAndSkinSubscriptions,
  render: ({ nextMode }) => (
    <DefaultBanner data-testid="banner">
      <LinkCta
        data-click="refer"
        data-from="banner"
        data-testid="referral-cta-banner"
        nextMode={nextMode}
        to="/account/refer-a-friend"
      >
        {bannerContent.referralCta}
      </LinkCta>
    </DefaultBanner>
  ),
};

export const giftWithPurchase = {
  shouldRender: ({ isGiftWithPurchaseEligible, giftWithPurchaseBannerData }) =>
    isGiftWithPurchaseEligible && Boolean(giftWithPurchaseBannerData),
  render: (_hideBannerLink, options) => (
    <GiftWithPurchaseBanner
      backgroundColor={options.giftWithPurchaseBannerData.banner_color ?? theme.colors.primary[300]}
      nextMode={options.nextMode}
      redirectionUrl={options.giftWithPurchaseBannerData.banner_redirection_url}
      text={options.giftWithPurchaseBannerData.banner_text}
    />
  ),
};

export const membershipCta = {
  shouldRender: ({
    hasCompletedHaircareConsultation,
    hasCompletedSkincareConsultation,
    hasStartedHaircareConsultation,
    hasStartedSkincareConsultation,
  }) =>
    hasCompletedHaircareConsultation ||
    hasCompletedSkincareConsultation ||
    (hasStartedHaircareConsultation && hasStartedSkincareConsultation),
  render: (
    hideBannerLink,
    { hasCompletedHaircareConsultation, hasCompletedSkincareConsultation, nextMode }
  ) => (
    <MembershipBanner data-testid="banner" props={{ hasLink: !hideBannerLink }}>
      <LinkCtaNoir
        data-click="membership-lp-prospects"
        data-from="banner"
        data-testid="membership-cta"
        nextMode={nextMode}
        to={
          hasCompletedHaircareConsultation
            ? HAIRCARE_CART_URL
            : hasCompletedSkincareConsultation
            ? SKINCARE_CART_URL
            : FIRST_CONSULTATION_ROUTE
        }
      >
        {bannerContent.membershipCta}
      </LinkCtaNoir>
      {!hideBannerLink && <ReferFriendSideLink nextMode={nextMode} />}
    </MembershipBanner>
  ),
};

export const loading = {
  shouldRender: ({ isFlagsDoneFetching }) => !isFlagsDoneFetching,
  render: () => <LoadingBanner data-testid="loading-banner" />,
};

export const supplementsPages = {
  shouldRender: ({ locationPathname, isAuthenticated }) => {
    const match = /\/(pages\/supplements|products\/custom-hair-supplements)/.test(locationPathname);
    return !isAuthenticated && match;
  },
  render: ({ nextMode }) => (
    <DefaultBanner data-testid="banner">
      <LinkCta
        data-click="membership-lp-prospects"
        data-from="banner"
        data-testid="membership-cta"
        nextMode={nextMode}
        to="/the-salon"
      >
        {bannerContent.membershipCta}
      </LinkCta>
    </DefaultBanner>
  ),
};

export const membershipMultiCategoryWithoutSubscription = {
  shouldRender: ({
    hasCompletedHaircareConsultation,
    hasCompletedSkincareConsultation,
    hasStartedHaircareConsultation,
    hasStartedSkincareConsultation,
  }) =>
    (hasCompletedHaircareConsultation && hasCompletedSkincareConsultation) ||
    (hasCompletedHaircareConsultation && hasStartedSkincareConsultation) ||
    (hasCompletedSkincareConsultation && hasStartedHaircareConsultation),
  render: (_, { hasCompletedHaircareConsultation, hasCompletedSkincareConsultation, nextMode }) => (
    <MembershipBanner data-testid="membership-multi-category-banner-without-subscription">
      <LinkCtaNoir
        data-testid="membership-cta"
        nextMode={nextMode}
        to={
          hasCompletedHaircareConsultation && hasCompletedSkincareConsultation
            ? '/account/reorder'
            : hasCompletedHaircareConsultation
            ? '/consultation/skincare'
            : '/consultation/haircare'
        }
      >
        {bannerContent.membershipMultiCategoryCta}
      </LinkCtaNoir>
    </MembershipBanner>
  ),
};

export const membershipMultiCategoryWithOneSubscription = {
  shouldRender: ({ hasEitherHairOrSkinSubscription }) => hasEitherHairOrSkinSubscription,
  render: (
    _,
    {
      hasHaircareSubscription,
      hasSkincareSubscription,
      hasCompletedHaircareConsultation,
      hasCompletedSkincareConsultation,
      nextMode,
    }
  ) => (
    <MembershipBanner data-testid="membership-multi-category-banner-with-subscription">
      <LinkCtaNoir
        data-testid="membership-cta"
        nextMode={nextMode}
        to={
          hasHaircareSubscription &&
          hasCompletedHaircareConsultation &&
          hasCompletedSkincareConsultation
            ? SKINCARE_CART_URL
            : hasSkincareSubscription &&
              hasCompletedHaircareConsultation &&
              hasCompletedSkincareConsultation
            ? HAIRCARE_CART_URL
            : hasHaircareSubscription
            ? '/consultation/skincare'
            : '/consultation/haircare'
        }
      >
        {hasHaircareSubscription
          ? bannerContent.membershipMultiCategoryWithHaircareSubscriptionCta
          : bannerContent.membershipMultiCategoryWithSkincareSubscriptionCta}
      </LinkCtaNoir>
    </MembershipBanner>
  ),
};

export const postPurchaseCrossSellSubscription20Offer = {
  shouldRender: ({ isFlagsDoneFetching, postPurchaseCrossSellVariantName }) =>
    isFlagsDoneFetching && postPurchaseCrossSellVariantName === Variant.SUBSCRIPTION_20_OFFER,
  render: (_, { hasCompletedSkincareConsultation, nextMode }) => {
    const linkTo = hasCompletedSkincareConsultation ? SKINCARE_CART_URL : '/consultation/skincare';

    return (
      <MembershipBanner data-testid="subscription-20-banner">
        <LinkCtaNoir
          data-testid="banner-link-to-consultation-or-cart"
          nextMode={nextMode}
          to={linkTo}
        >
          {bannerContent.membershipMultiCategoryCta}
        </LinkCtaNoir>
      </MembershipBanner>
    );
  },
};

export const postPurchaseCrossSellFreeCleanserOffer = {
  shouldRender: ({ isFlagsDoneFetching, postPurchaseCrossSellVariantName }) =>
    isFlagsDoneFetching && postPurchaseCrossSellVariantName === Variant.FREE_CLEANSER_OFFER,
  render: (_, { hasCompletedSkincareConsultation, nextMode }) => {
    const linkTo = hasCompletedSkincareConsultation ? SKINCARE_CART_URL : '/consultation/skincare';

    return (
      <MembershipBanner data-testid="free-cleanser-banner">
        <LinkCtaNoir
          data-testid="banner-link-to-consultation-or-cart"
          nextMode={nextMode}
          to={linkTo}
        >
          {bannerContent.freeCleanserCta}
        </LinkCtaNoir>
      </MembershipBanner>
    );
  },
};

const PostPurchase = ({ nextMode }) => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleModal = useCallback(value => {
    setIsOpened(value);
  }, []);

  return (
    <MembershipBanner data-testid="first-order-50-banner">
      <UnstyledButton
        color="noir"
        data-click="banner-button-to-post-purchase-cross-sell-popup"
        data-from="banner"
        onClick={() => toggleModal(true)}
        onKeyPress={handleKeyPress(() => toggleModal(true))}
      >
        {bannerContent.firstOrder50Cta}
        <PromotionalModalPostPurchaseCrossSell
          disableDelay
          isOpen={isOpened}
          nextMode={nextMode}
          toggleIsOpened={toggleModal}
        />
      </UnstyledButton>
    </MembershipBanner>
  );
};

PostPurchase.propTypes = {
  nextMode: PropTypes.bool.isRequired,
};

export const postPurchaseCrossSellFirstOrder50Offer = {
  shouldRender: ({ isFlagsDoneFetching, postPurchaseCrossSellVariantName }) =>
    isFlagsDoneFetching && postPurchaseCrossSellVariantName === Variant.FIRST_ORDER_50_OFFER,
  render: (_hideBannerLink, { nextMode }) => <PostPurchase nextMode={nextMode} />,
};

export const promotionAccessoriesOffer = {
  shouldRender: ({ locationPathname, showPromotionAccessories2023 }) => {
    const match = /\/(checkout|consultation)/.test(locationPathname);
    return !match && showPromotionAccessories2023;
  },
  render: ({ nextMode }) => (
    <DefaultBanner>
      <LinkCtaWhite
        data-click="accessories-promo-dec-2023"
        data-from="banner"
        data-testid="promotion-accessories-link"
        nextMode={nextMode}
        to="/products/accessories"
      >
        {bannerContent.accessoriesPromotion2023BannerText}
      </LinkCtaWhite>
    </DefaultBanner>
  ),
};
