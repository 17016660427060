import currencies from 'constants/currencies';

import imageGiftCAD from 'assets/images/promotionalModals/trial-offer-skincare-minis-promotion-modal-CAD-gift.jpg';
import imageGiftUS from 'assets/images/promotionalModals/trial-offer-skincare-minis-promotion-modal-US-gift.jpg';

const tracking = {
  modalClosing: 'Landing - SK Minis + HC trial Popup - Closed',
  modalOpening: 'Landing - SK Minis + HC trial Popup - Viewed',
  modalOpening2sec: 'Landing - SK Minis + HC trial Popup - 2 Sec Viewed',
};

const content = {
  banner: 'Get 50% off haircare + free gift, or try skincare for free',
  popUpModal: {
    [currencies.USD]: {
      tracking,
      image: imageGiftUS,
      headerTitle: 'EXCLUSIVE TRIAL OFFER',
      description: {
        mobile: (
          <>
            Claim your exclusive trial offer by subscribing to any 3+ hair or skin products.
            <br />
            Limited time only!
          </>
        ),
        desktop:
          'Claim your exclusive trial offer by subscribing to any 3+ hair or skin products. Limited time only!',
      },
      cta: {
        ctaLabel: 'unlock offer',
      },
      conditions: (
        <>
          *Get [1] free gift and a total of 50% off your first haircare subscription order when you
          subscribe to 3+ products. Get your Skincare Starter Set ($65 value) when you subscribe to
          Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free
          shipping and 15% off. Offers subject to expire and valid only while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. Exceptions may apply. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      tracking,
      image: imageGiftCAD,
      headerTitle: 'EXCLUSIVE TRIAL OFFER',
      description: {
        mobile: (
          <>
            Claim your exclusive trial offer by subscribing to any 3+ hair or skin products.
            <br />
            Limited time only!
          </>
        ),
        desktop:
          'Claim your exclusive trial offer by subscribing to any 3+ hair or skin products. Limited time only!',
      },
      cta: {
        ctaLabel: 'unlock offer',
      },
      conditions: (
        <>
          *Get [1] free gift and a total of 50% off your first haircare subscription order when you
          subscribe to 3+ products. Get your Skincare Starter Set when you subscribe to Cleanser,
          Serum, and Moisturizer. Subsequent subscription orders will receive free shipping and 15%
          off. Offers subject to expire and valid only while supplies last. Cannot be applied to
          previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
          Exceptions may apply. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
  },
  bottomModal: {
    [currencies.USD]: {
      title: 'EXCLUSIVE TRIAL OFFER',
      description: (
        <>
          Unlock <b>50% off haircare + a FREE gift</b>, or a <b>FREE Skincare Starter Set</b> when
          you subscribe to 3+ products.
        </>
      ),
      conditions: (
        <>
          *Get [1] free gift and a total of 50% off your first haircare subscription order when you
          subscribe to 3+ products. Get your Skincare Starter Set ($65 value) when you subscribe to
          Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free
          shipping and 15% off. Offers subject to expire and valid only while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. Exceptions may apply. For full details on our return policy, click{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      title: 'EXCLUSIVE TRIAL OFFER',
      description: (
        <>
          Unlock <b>50% off haircare + a FREE gift</b>, or a <b>FREE Skincare Starter Set</b> when
          you subscribe to 3+ products.
        </>
      ),
      conditions: (
        <>
          *Get [1] free gift and a total of 50% off your first haircare subscription order when you
          subscribe to 3+ products. Get your Skincare Starter Set when you subscribe to Cleanser,
          Serum, and Moisturizer. Subsequent subscription orders will receive free shipping and 15%
          off. Offers subject to expire and valid only while supplies last. Cannot be applied to
          previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
          Exceptions may apply. For full details on our return policy, click{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            here
          </a>
          .
        </>
      ),
    },
  },
} as const;

export default content;
