import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import orderBy from 'lodash/fp/orderBy';
import set from 'lodash/fp/set';

import * as Sentry from '@sentry/nextjs';
import { createSelector } from 'reselect';

import agnosticQuestionSet from 'Apps/Consultation/agnosticQuestionSet';
import {
  FIRST_SKINCARE_QUESTION_ROUTE,
  STYLING_GEL_CONSULTATION_QUESTION_SET_KEY,
  SUPPLEMENTS_CONSULTATION_QUESTION_SET_KEY,
} from 'Apps/Consultation/constants';
import hairQuestionSet, {
  signinFirstSet as hairSigninFirstQuestionSet,
} from 'Apps/Consultation/hairQuestionSet';
import {
  defaultSet as skinQuestionSet,
  signinFirstSet as skinSigninFirstQuestionSet,
} from 'Apps/Consultation/skinQuestionSet';
import stylingGelQuestionSet from 'Apps/Consultation/stylingGelQuestionSet';
import supplementsQuestionSet from 'Apps/Consultation/supplementsQuestionSet';

import { productsCategories, productsSlugs } from 'constants/products';

import { BLANCA, FRAGRANCE_FREE, PACIFICA } from 'assets/content/skincareFragrances';
import { skincareRecommendedReasons } from 'assets/content/skincareRecommendedReasons';
import { cleanserTextures, moisturizerTextures } from 'assets/content/skincareTextures';

import { createApiSelectors } from 'utils/reduxUtils';

import { getIsAuthenticated } from 'dux/auth/selectors';
import { getPrescriptionStatus } from 'dux/prescription/selectors';
import {
  getHasAnsweredStylingGelQuestions,
  getHasAnsweredSupplementsQuestions,
} from 'dux/user/selectors';

const getState = state => state.consultation;
const getAnswers = createSelector(getState, state => state.answers);
const getHairProfileProductsVariants = createSelector(getState, state => state.productsVariants);
const getBufferedAnswers = createSelector(getState, state => state.bufferedAnswers);
const getRecommendations = createSelector(getState, state => state.recommendations);
export const getStartedFrom = createSelector(getState, state => state?.origin?.startedFrom || '');

export const getHairProfileProductsVariantsList = createSelector(
  getHairProfileProductsVariants,
  get('data')
);

export const getAnswersData = createSelector(getAnswers, answers => answers.data);

export const getBufferedAnswersData = createSelector(getBufferedAnswers, get('bufferedData'));

export const getRecommendationsData = createSelector(getRecommendations, get('data'));

export const hasBufferedAnswers = createSelector(
  getBufferedAnswersData,
  bufferedData => !isEmpty(bufferedData)
);

const getConsultationRoutes = createSelector(getState, state => state.consultationRoutes);

export const getFirstHaircareConsultationQuestionRoute = createSelector(
  getConsultationRoutes,
  get('firstHaircareQuestion')
);
export const getFirstSkincareConsultationQuestionRoute = createSelector(
  getConsultationRoutes,
  get('firstSkincareQuestion')
);

export const getConsultationAnswers = createSelector(
  hasBufferedAnswers,
  getBufferedAnswersData,
  getAnswersData,
  (userHasBufferedAnswers, bufferedAnswers, answersData) =>
    userHasBufferedAnswers ? bufferedAnswers : answersData
);

export const getConsultationProfileCountry = createSelector(
  getConsultationAnswers,
  answersData => answersData?.profile_country
);

export const getIsSavingAnswer = createSelector(getAnswers, answers => answers.isSaving);

export const getIsConsultationLoading = createSelector(getAnswers, answers => answers.isLoading);

export const getConsultationError = createSelector(getAnswers, answers => answers.error);

const [getFragrancesData, getFragrancesError, getFragrancesStatus] = createApiSelectors(
  state => state.consultation.fragrances
);

export { getFragrancesData, getFragrancesError, getFragrancesStatus };

const [getSkincareFragrancesData, getSkincareFragrancesError, getSkincareFragrancesStatus] =
  createApiSelectors(state => state.consultation.skincareFragrances);

export { getSkincareFragrancesData, getSkincareFragrancesError, getSkincareFragrancesStatus };

const [getSkincareTexturesData, getSkincareTexturesError, getSkincareTexturesStatus] =
  createApiSelectors(state => state.consultation.skincareTextures);

export { getSkincareTexturesData, getSkincareTexturesError, getSkincareTexturesStatus };

export const getSkincareTexturesDetails = createSelector(
  getSkincareTexturesData,
  flow(
    map(skeleton => {
      const rankedSkeletons = flow(
        map(texture => {
          const content = { ...cleanserTextures, ...moisturizerTextures }[texture.skeleton_slug];
          if (!content) {
            Sentry.captureMessage(`Unknown skincare texture ${texture.skeleton_slug}`);
            return null;
          }
          return { ...texture, ...content };
        }),
        orderBy(['rank'], ['asc'])
      )(skeleton.ranked_skeletons);
      return { ...skeleton, ranked_skeletons: rankedSkeletons };
    })
  )
);

const [getSkincareScoringData, getSkincareScoringError, getSkincareScoringStatus] =
  createApiSelectors(state => state.consultation.skincareScoring);

export { getSkincareScoringData, getSkincareScoringError, getSkincareScoringStatus };

const [getAggressorsData, getAggressorsError, getAggressorsStatus] = createApiSelectors(
  state => state.consultation.aggressors
);
export { getAggressorsData, getAggressorsError, getAggressorsStatus };

const [getIngredientsData, getIngredientsError, getIngredientsStatus] = createApiSelectors(
  state => state.consultation.ingredients
);

export { getIngredientsData, getIngredientsError, getIngredientsStatus };

const [getSkincareIngredientsData, getSkincareIngredientsError, getSkincareIngredientsStatus] =
  createApiSelectors(state => state.consultation.skincareIngredients);

export { getSkincareIngredientsData, getSkincareIngredientsError, getSkincareIngredientsStatus };

const [getSkincareIncisData, getSkincareIncisError, getSkincareIncisStatus] = createApiSelectors(
  state => state.consultation.skincareIncis
);

export const getOrderedIncisData = createSelector(
  getSkincareIncisData,
  orderBy(['rank'], ['desc'])
);

export { getSkincareIncisData, getSkincareIncisError, getSkincareIncisStatus };

const [getHaircareIncisData, getHaircareIncisError, getHaircareIncisStatus] = createApiSelectors(
  state => state.consultation.haircareIncis
);

export { getHaircareIncisData, getHaircareIncisError, getHaircareIncisStatus };

const [getScoringData, getScoringError, getScoringStatus] = createApiSelectors(
  state => state.consultation.scoring
);
export { getScoringData, getScoringError, getScoringStatus };

export const getSkincareFragrancesDetails = createSelector(
  getSkincareFragrancesData,
  flow(
    map(fragrance => {
      const content = { FRAGRANCE_FREE, PACIFICA, BLANCA }[fragrance.name];
      const reasonWhyLabel = skincareRecommendedReasons[fragrance.reason_why_slug];
      if (!content) {
        Sentry.captureMessage(`Unknown skincare fragrance ${fragrance.name}`);
        return null;
      }
      return { ...fragrance, ...content, reasonWhyLabel };
    }),
    orderBy(['rank'], ['asc'])
  )
);

export const getResultsStatus = createSelector(
  getIngredientsStatus,
  getPrescriptionStatus,
  (ingredientsStatus, prescriptionStatus) => {
    const statuses = [ingredientsStatus, prescriptionStatus];

    if (statuses.includes('error')) {
      return 'error';
    }

    if (statuses.some(status => status === 'loading' || status === 'pending')) {
      return 'loading';
    }

    if (statuses.every(status => status === 'success' || status === 'fulfilled')) {
      return 'success';
    }

    return 'idle';
  }
);

export const getResultsError = createSelector(
  getIngredientsError,
  getScoringError,
  (ingredientsError, scoringError) => ingredientsError || scoringError
);

export const userShouldHaveSigninFirst = createSelector(
  (_state, { routeParams } = {}) => routeParams,
  routeParams => {
    return routeParams?.has('signin-first') && routeParams?.get('signin-first') !== 'false';
  }
);

export const userShouldHaveSupplementsQuestion = createSelector(
  getHasAnsweredSupplementsQuestions,
  (_state, { consultationSubsetCategory } = {}) => consultationSubsetCategory,
  (hasAnsweredSupplementsQuestion, consultationSubsetCategory) =>
    !hasAnsweredSupplementsQuestion &&
    consultationSubsetCategory === SUPPLEMENTS_CONSULTATION_QUESTION_SET_KEY
);

export const userShouldHaveStylingGelQuestion = createSelector(
  getHasAnsweredStylingGelQuestions,
  (_state, { consultationSubsetCategory } = {}) => consultationSubsetCategory,
  (hasAnsweredStylingGelQuestion, consultationSubsetCategory) =>
    !hasAnsweredStylingGelQuestion &&
    consultationSubsetCategory === STYLING_GEL_CONSULTATION_QUESTION_SET_KEY
);

export const getCurrentlyLoadedHairQuestionSet = createSelector(
  userShouldHaveSigninFirst,
  userShouldHaveSupplementsQuestion,
  userShouldHaveStylingGelQuestion,
  (shouldHaveSigninFirst, shouldLoadSupplementsQuestion, shouldLoadStylingGelQuestion) => {
    let loadedQuestionSet = hairQuestionSet;
    if (shouldLoadStylingGelQuestion) {
      loadedQuestionSet = stylingGelQuestionSet;
    } else if (shouldLoadSupplementsQuestion) {
      loadedQuestionSet = supplementsQuestionSet;
    } else if (shouldHaveSigninFirst) {
      loadedQuestionSet = hairSigninFirstQuestionSet;
    }

    return loadedQuestionSet;
  }
);

export const getCurrentlyLoadedSkinQuestionSet = createSelector(
  userShouldHaveSigninFirst,
  shouldHaveSigninFirst => (shouldHaveSigninFirst ? skinSigninFirstQuestionSet : skinQuestionSet)
);

export const getCurrentlyLoadedQuestionSet = createSelector(
  getCurrentlyLoadedHairQuestionSet,
  getCurrentlyLoadedSkinQuestionSet,
  (_state, { consultationCategory } = {}) => consultationCategory,
  (haircareQuestions, skincareQuestions, consultationCategory) => {
    let currentlyLoadedQuestionSet = agnosticQuestionSet;
    if (consultationCategory === productsCategories.HAIRCARE) {
      currentlyLoadedQuestionSet = haircareQuestions;
    } else if (consultationCategory === productsCategories.SKINCARE) {
      currentlyLoadedQuestionSet = skincareQuestions;
    }

    return currentlyLoadedQuestionSet;
  }
);

export const getZipcodeQuestionRoute = createSelector(
  getCurrentlyLoadedQuestionSet,
  currentlyLoadedQuestionSet =>
    currentlyLoadedQuestionSet.find(question => question.name === 'zipcode')?.route
);

export const getConsultationQuestionForIndex = createSelector(
  getCurrentlyLoadedQuestionSet,
  (_state, { questionIndex } = {}) => questionIndex,
  (questions, questionIndex) => {
    const nextQuestion = questions?.[questionIndex];
    return nextQuestion;
  }
);

const getRouteForQuestionIndex = createSelector(getConsultationQuestionForIndex, get('route'));

export const getTipForQuestion = createSelector(
  getConsultationQuestionForIndex,
  getConsultationAnswers,
  (questionContent, answers) => {
    const filters = get('optionsFilter')(questionContent)?.({ answers });

    if (filters && get('name', questionContent) === 'goals') {
      const tipContent = get('tip.content')(questionContent);
      return flow(
        get('tip'),
        set(
          'content',
          flow(
            filter(({ value }) => filters[value]),
            map('content'),
            join('')
          )(tipContent)
        )
      )(questionContent);
    }
    if (get('name', questionContent) === 'hair-style') {
      return get('tip')(questionContent)(answers);
    }
    return get('tip')(questionContent);
  }
);
export const getOptionsForQuestion = createSelector(
  getConsultationQuestionForIndex,
  getConsultationAnswers,
  (questionContent, answers) => {
    let options = get('options')(questionContent);
    const filters = answers && get('optionsFilter')(questionContent)?.({ answers });

    if (filters) {
      const predicate = ({ value }) => filters[value];
      options = filter(predicate)(options);
    }
    if (typeof options === 'function') {
      return options(answers);
    }
    return options;
  }
);

export const getInitialValue = createSelector(
  getConsultationQuestionForIndex,
  getConsultationAnswers,
  (question, answers) => {
    if (question.getInitialValue && answers) {
      return question.getInitialValue(answers);
    }

    return question.multi ? [] : null;
  }
);

export const getHairTexture = createSelector(
  getConsultationAnswers,
  /* In case of empty string we wants null or undefined instead */
  answers => answers?.diag_hair_texture || null
);

export const getShouldInitializeConsultation = createSelector(
  getIsAuthenticated,
  getAnswers,
  (userIsAuthenticated, { data, isLoading, didInvalidate, error }) =>
    userIsAuthenticated && ((!data && !isLoading && !error) || didInvalidate)
);

export const shouldTrackConsultationStart = createSelector(
  getRouteForQuestionIndex,
  getFirstHaircareConsultationQuestionRoute,
  (currentRoute, firstHaircareConsultationQuestionRoute) => {
    const firstQuestionRouteByCategory = {
      [productsCategories.HAIRCARE]: firstHaircareConsultationQuestionRoute,
      [productsCategories.SKINCARE]: FIRST_SKINCARE_QUESTION_ROUTE,
    };
    return Object.values(firstQuestionRouteByCategory).includes(currentRoute);
  }
);

export const getEmailCaptureExitConsultationModalOpened = createSelector(
  getAnswers,
  get('emailCaptureExitConsultationModalOpened')
);

export const getStylingGelHoldLevel = createSelector(
  getConsultationAnswers,
  get('diag_hold_level')
);

export const getStylingGelRecommendation = createSelector(
  getRecommendationsData,
  recommendations => {
    if (recommendations) {
      return recommendations?.[productsSlugs.STYLING_GEL]?.recommendation_slug ?? null;
    }
    return null;
  }
);
