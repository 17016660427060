import logSentryError from 'utils/logSentry';

import { authClient } from './AuthClient';
import { isFetchError } from './HTTPError';

type TS_TODO = any;

const customBaseQuery = async ({
  url,
  method = 'GET',
  json = undefined,
  endpointName,
}: {
  url: string;
  method?: 'GET' | 'POST' | 'PATCH';
  json?: TS_TODO;
  endpointName?: string;
}) => {
  try {
    const result = await authClient(url, { json, method }); // FIXME: throwing (e.g. timeout) results in an error log in console: "A non-serializable value was detected in the state"

    return { data: await result.json() };
  } catch (error) {
    if (!isFetchError(error) /* do not send network errors, CORS errors, and similar */) {
      logSentryError(`[${endpointName ?? url}] customBaseQuery`, error);
    }
    return { error };
  }
};

export default customBaseQuery;
