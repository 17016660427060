export const Goals = 'Goals';
export const FragranceContainer = 'FragranceContainer';
export const GeoAggressorsContainer = 'GeoAggressorsContainer';
export const ZipCodeContainer = 'ZipCodeContainer';
export const SignIn = 'SignIn';
export const DefaultQuestionContainer = 'DefaultQuestionContainer';
export const Transition = 'Transition';
export const Welcome = 'Welcome';
export const StartStylingGelInterstitial = 'StartStylingGelInterstitial';
export const SkincareRoutineProducts = 'SkincareRoutineProducts';
export const SkincareFragrance = 'SkincareFragrance';
export const MedicalModal = 'MedicalModal';
export const SkincareDiet = 'SkincareDiet';
export const SkincareTextures = 'SkincareTextures';
