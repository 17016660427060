import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useAppDispatch as useDispatch, useAppSelector } from 'dux/app/hooks';

import { Modal, theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import Image from 'next/image';

import { countriesCode } from 'constants/countries';

import content, { rootToastContent } from 'assets/content/promotionalModalPostPurchaseCrossSell';

import { createTranslationFn } from 'utils/createTranslationFn';
import useResponsiveVariant from 'utils/useResponsiveVariant';

import { setRootToast } from 'dux/user/actions';
import {
  getHasCompletedSkincareConsultation,
  getUserGeolocationGuessedCountry,
} from 'dux/user/selectors';
import {
  usePostPurchaseCrossSellOffer as usePostPurchaseCrossSellOfferFn,
  Variant,
} from 'dux/featureFlags/postPurchaseCrossSellOfer';

import OgArchedText from './ArchedText';
import AutoPlayVideo from './AutoPlayVideo';
import { HybridLink } from './HybridLink';
import Button from './LegacyButton';
import Typography from './Typography';

/**
 * Derive a new Modal component from @prose-ui
 * basically, we can use 'up' instead of match
 */
const StyledModal = styled(Modal)`
  --first-row-width: minmax(274px, 0.5fr);
  --img-width: clamp(180px, 65%, 30vh);
  display: grid;

  grid-template-rows: var(--first-row-width) auto;
  justify-content: center;

  padding: 0;

  ${legacyTheme.breakpoints.up('sm')} {
    --first-row-width: minmax(274px, 1fr);
  }
`;

/**
 * Main container for the images
 *
 * Order of components within this container:
 * <IllusContainer>
 *  <BackgroundFiller>
 *  <ArchedText>
 *  <ArchedImageContainer>
 *    <ArchedImage>
 *  <BadgeContainer>
 *    <BadgeImage>
 */
const IllusContainer = styled.div`
  --img-width: clamp(180px, 65%, 30vh);
  /* distance between the top of the arch & the top of the container */
  --v-offset: calc(${theme.spacing['10x']} + ${theme.spacing['2x']});

  position: relative;

  overflow-x: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) var(--img-width) minmax(0, 1fr);
  grid-template-rows: 1.5rem min-content 1.3fr 2fr;
  grid-template-areas:
    '... ... ...'
    '... txt ...'
    '... img ...'
    '... img ...';

  height: 100%;
  min-height: fit-content;

  font-size: 0.8rem;

  ${legacyTheme.breakpoints.up('sm')} {
    /* above mobile we can rely on a fixed width */
    --img-width: 238px;
  }
`;

const BackgroundFiller = styled.div`
  /* it fills the whole column */
  grid-column: 1 / -1;
  /* but spans only on row 1 */
  grid-row: 1;

  height: calc(var(--img-width) / 2 + var(--v-offset));

  background: ${theme.colors.primary[300]};
`;

const ArchedText = styled(OgArchedText)`
  justify-self: center;

  width: min(100%, 178px);

  ${legacyTheme.breakpoints.up('sm')} {
    width: var(--img-width);
  }
`;

const ArchedMedia = styled(AutoPlayVideo)`
  /* to increase fidelity to the mock we zoom the img */
  /* transform: scale(1.4); */

  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
`;
// Handles the placement of the ArchedImage
const ArchedMediaContainer = styled.div`
  /* badge is positioned absolutely to the arched image */
  position: relative;

  grid-area: img;
  justify-self: center;

  /* we zoom the img & want a crop effect */
  overflow: hidden;

  width: min(100%, var(--img-width));
  max-height: 226px;

  border-radius: 100vmax 100vmax 0 0;

  ${legacyTheme.breakpoints.up('sm')} {
    max-height: 303px;
  }
`;

const BadgeImage = styled(Image)`
  display: block;

  width: 100%;
  height: 100%;
`;
// Handles the placement of the BadgeImage
const BadgeContainer = styled.div`
  --badge-size: 88px;

  position: absolute;
  right: calc(-1 * var(--badge-size) / 2);

  grid-area: img;
  place-self: center;

  width: var(--badge-size);
  height: var(--badge-size);

  ${legacyTheme.breakpoints.up('sm')} {
    --badge-size: 114px;

    /* no need of absolute positioning above mobile */
    position: revert;

    /* translate is easier to manipulate in this case */
    transform: translate(calc(50% + var(--badge-size) / 2), calc(var(--badge-size) / 2));
  }
`;

/**
 * Main container for the text & buttons
 *
 * Order of components within this container:
 * <Container>
 *  <DiscountTitle>
 *  <Typography>
 *  <Button>
 *  <ClickableTypography>
 *  <Typography>
 */
const Container = styled.div`
  display: grid;
  gap: 0.8rem;
  justify-items: center;
  overflow-x: hidden;

  padding-block: min(${legacyTheme.spacing.s24}, 4vh) min(${legacyTheme.spacing.s32}, 4vh);
  padding-inline: ${legacyTheme.spacing.s24} ${legacyTheme.spacing.s24};
  height: auto;

  ${legacyTheme.breakpoints.up('sm')} {
    padding-top: ${legacyTheme.spacing.s16};
    padding-bottom: ${legacyTheme.spacing.s32};
    padding-inline: ${legacyTheme.spacing.s144};
  }
`;

const DiscountTitle = styled(Typography)`
  max-width: 28ch;
  @media (max-height: 660px), (max-width: 405px) {
    font-size: min(
      26px,
      3.75vh
    ); /* title would take a lot of vertical space on very small devices */
  }
`;

const ClickableTypography = styled(Typography)`
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
`;

const ConditionsText = styled(Typography)`
  max-width: 65ch;
`;

export const PromotionalModalPostPurchaseCrossSell = ({
  isOpen,
  onClose: closeModal,
  countryCode,
  nextMode,
  variant: variantName,
  onConfirm,
  hasCompletedSkincareConsultation,
}) => {
  const { isMobile } = useResponsiveVariant();
  const t = createTranslationFn(content, countryCode, 'US');

  const ctaProps = hasCompletedSkincareConsultation
    ? t(`${variantName}.cta.skincareCompleted`)
    : t(`${variantName}.cta.skincareNotCompleted`);

  return (
    <StyledModal hideClose isOpen={isOpen} onClose={closeModal}>
      <IllusContainer>
        <BackgroundFiller />
        <ArchedText fontSize={isMobile ? '0.75rem' : '1rem'}>
          {t(`${variantName}.archedText`)}
        </ArchedText>
        <ArchedMediaContainer>
          <ArchedMedia
            desktopVideo="post-purchase-cross-sell-modal-skincare"
            mobileVideo="post-purchase-cross-sell-modal-skincare"
          />
        </ArchedMediaContainer>

        <BadgeContainer data-testid={`post-purchase-discount-badge-${variantName}`}>
          <BadgeImage
            alt={t(`${variantName}.badge.alt`)}
            height={114}
            src={t(`${variantName}.badge.src`)}
            width={114}
          />
        </BadgeContainer>
      </IllusContainer>

      <Container>
        <DiscountTitle
          align="center"
          data-testid={`post-purchase-discount-modal-title-${variantName}`}
          variant="h2"
        >
          {t(`${variantName}.title`)}
        </DiscountTitle>
        {t(`${variantName}.description`) && (
          <Typography
            align="center"
            data-testid={`skincare-welcome-kit-modal-${countryCode}-description`}
            style={{ maxWidth: '45ch' }}
            variant="p1"
          >
            {t(`${variantName}.description`)}
          </Typography>
        )}

        <Button
          Component={HybridLink}
          data-click={ctaProps.dataClick}
          data-from="post-purchase-popup"
          nextMode={nextMode}
          onClick={onConfirm}
          to={ctaProps.linkTo()}
          variant="vert"
        >
          {ctaProps.label}
        </Button>
        <ClickableTypography
          data-click="decline-offer"
          data-from="post-purchase-popup"
          onClick={closeModal}
          underline
        >
          No thanks, I’ll pass on this offer
        </ClickableTypography>
        {t(`${variantName}.conditions`) && (
          <ConditionsText align="center" color="grey" italic variant="p4">
            {t(`${variantName}.conditions`)}
          </ConditionsText>
        )}
      </Container>
    </StyledModal>
  );
};

PromotionalModalPostPurchaseCrossSell.propTypes = {
  isOpen: PropTypes.bool,
  nextMode: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    Variant.NONE,
    Variant.SUBSCRIPTION_20_OFFER,
    Variant.FREE_CLEANSER_OFFER,
    Variant.FIRST_ORDER_50_OFFER,
  ]).isRequired,
  countryCode: PropTypes.oneOf(['US', 'CA']).isRequired,
  hasCompletedSkincareConsultation: PropTypes.bool.isRequired,
};

PromotionalModalPostPurchaseCrossSell.defaultProps = {
  isOpen: false,
};

/* Business rules to display or not the RootToast */
const useRootToastFn = ({ variantName }) => {
  const dispatch = useDispatch();

  const openRootToast = useCallback(() => {
    dispatch(setRootToast(rootToastContent[variantName]));
  }, [variantName, dispatch]);

  return { openRootToast };
};

/**
 * Toggle hook
 * @param {boolean} [initialValue=false]
 *
 * @returns {[boolean, (forcedValue: boolean) => void]}
 */
const useToogle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(
    forcedValue => (forcedValue ? setValue(forcedValue) : setValue(val => !val)),
    [initialValue]
  );

  return [value, toggle];
};
const WithOwnStatePostPurchaseCrossSellModal = ({
  countryCode: forcedCountryCode,
  disableDelay = false,
  isOpen: forcedIsOpened,
  nextMode,
  toggleIsOpened: forcedTogleIsOpened,
}) => {
  const [isOpened, toggleIsOpened] = useToogle(forcedIsOpened);
  const variantName = usePostPurchaseCrossSellOfferFn();
  const hasCompletedSkincareConsultation = useAppSelector(getHasCompletedSkincareConsultation);
  const { openRootToast } = useRootToastFn({
    variantName,
  });
  const geolocatedCountry = useAppSelector(getUserGeolocationGuessedCountry);
  const countryCode = forcedCountryCode ?? geolocatedCountry;

  const shouldShowModal = variantName !== Variant.NONE;

  const [toggled, toggle] = forcedTogleIsOpened
    ? [forcedIsOpened, forcedTogleIsOpened]
    : [isOpened, toggleIsOpened];

  useEffect(() => {
    let timeout;
    if (!disableDelay && shouldShowModal) {
      timeout = setTimeout(() => {
        toggle();
      }, 1800);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [disableDelay, shouldShowModal, toggle]);

  if (!shouldShowModal) return null;

  return (
    <PromotionalModalPostPurchaseCrossSell
      countryCode={countryCode}
      hasCompletedSkincareConsultation={hasCompletedSkincareConsultation}
      isOpen={toggled}
      nextMode={nextMode}
      onClose={() => toggle(false)}
      onConfirm={openRootToast}
      variant={variantName}
    />
  );
};
WithOwnStatePostPurchaseCrossSellModal.propTypes = {
  disableDelay: PropTypes.bool,
  isOpen: PropTypes.bool,
  nextMode: PropTypes.bool,
  toggleIsOpened: PropTypes.func,
  countryCode: PropTypes.oneOf([countriesCode.US, countriesCode.CA, null]),
};
WithOwnStatePostPurchaseCrossSellModal.defaultProps = {
  disableDelay: false,
  isOpen: false,
  nextMode: false,
  toggleIsOpened: null,
  countryCode: null,
};

export default WithOwnStatePostPurchaseCrossSellModal;
